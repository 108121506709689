@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply font-roboto;
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .animation-slide-down-and-fade {
    animation-name: slideDownAndFade;
    animation-duration: 500ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  }

  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .animation-spin-infinite {
    animation: SpinnerAnimation 0.8s infinite linear;
  }

  @keyframes SpinnerAnimation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .input-picker-scrollbar::-webkit-scrollbar {
    width: 3px;
  }

  .input-picker-scrollbar::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  .input-picker-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 6px;
  }

  .date-picker-animation {
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
    z-index: 2;
  }

  .date-picker-animation[data-state='open'][data-side='top'] {
    animation-name: slideDownAndFade;
  }

  .date-picker-animation[data-state='open'][data-side='right'] {
    animation-name: slideLeftAndFade;
  }

  .date-picker-animation[data-state='open'][data-side='bottom'] {
    animation-name: slideUpAndFade;
  }

  .date-picker-animation[data-state='open'][data-side='left'] {
    animation-name: slideRightAndFade;
  }

  @keyframes slideUpAndFade {
    from {
      opacity: 0;
      transform: translateY(2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideRightAndFade {
    from {
      opacity: 0;
      transform: translateX(-2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: translateY(-2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideLeftAndFade {
    from {
      opacity: 0;
      transform: translateX(2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .select-content-scrollbar::-webkit-scrollbar {
    width: 3px;
  }

  .select-content-scrollbar::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  .select-content-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 6px;
  }

  .select-content-animation {
    transform-origin: var(--radix-select-content-transform-origin);
    animation: slideDownAndFade 0.2s ease-out;
  }

  @keyframes selectContentSlideDownAndFade {
    from {
      opacity: 0;
      transform: scaleY(10%);
    }
    to {
      opacity: 1;
      transform: scaleY(100%);
    }
  }

  .animation-slide-down-and-fade {
    animation: slideDownAndFade 0.1s ease-out;
  }

  .autocomplete-scrollbar::-webkit-scrollbar {
    width: 4px;
  }

  .select-list-size {
    height: var(--radix-select-trigger-height);
    min-width: var(--radix-select-trigger-width);
  }

  .animation-pop-from-top {
    animation-delay: 0.8s;
    animation: popFromTop 0.8s ease-in-out;
  }

  @keyframes popFromTop {
    0% {
      transform: scale(0.1) translateX(-100px);
      opacity: 0.3;
    }
    30% {
      transform: scale(1) translateX(0px);
      opacity: 1;
    }
  }

  .will-change {
    @apply will-change-[transform,opacity];
  }

  .animation-shine-through {
    animation: 1.5s shine linear infinite;
    background: #ededed;
    background: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%
      )
      #ededed;
    background-size: 200% 100%;
  }

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
}
